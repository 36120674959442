import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import SeamIcon from "../images/seamIcon.svg";
import { Box } from "@mui/material";
import React from "react";

export default function BottomBar() {
  return (
    <Box
      sx={{
        width: "80%",
        display: "flex",
        justifyContent:
          "space-between" /* changed from "center" to "space-between" */,
        alignItems:
          "center" /* added to vertically center the icons and "EST" */,
      }}
    >
      <div>
        <a href="https://github.com/Jesse-Sawa">
          <GitHubIcon
            className={"icon-link"}
            fontSize={"large"}
            style={{ marginRight: "10px" }}
          />
        </a>
        <a href="https://www.linkedin.com/in/jesse-sawa">
          <LinkedInIcon
            className={"icon-link"}
            fontSize={"large"}
            style={{ marginRight: "10px" }}
          />
        </a>
        <a href="https://twitter.com/jessesawa">
          <TwitterIcon className={"icon-link"} fontSize={"large"} />
        </a>
      </div>
      <div style={{ marginLeft: "auto" }}>📍EST</div>
    </Box>
  );
}
