import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import "./App.css";
import Sidebar from "./components/Sidebar";
import Life from "./pages/Life";
import Work from "./pages/work";
import { useMediaQuery } from "@mui/material";

function App() {
  const [viewBackstage, setViewBackstage] = useState(false);
  const handleViewChange = (event) => {
    setViewBackstage(event);
  };

  const isPhone = useMediaQuery("(max-width:600px)");

  return (
    <div className="portfolio">
      <Grid container>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            pb: 3,
            display: isPhone ? "flex" : "initial",
            justifyContent: isPhone ? "center" : "initial",
          }}
        >
          <Sidebar onViewChange={handleViewChange} />
        </Grid>
        {viewBackstage ? <Life /> : <Work />}
      </Grid>
    </div>
  );
}

export default App;
