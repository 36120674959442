import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  useMediaQuery,
} from "@mui/material";

import board from "../images/life/boarding.jpg";
import buda from "../images/life/budapest.jpg";
import fishes from "../images/life/fishes.jpg";
import olderNiece from "../images/life/olderNiece.jpg";
import youngerNiece from "../images/life/youngerNiece.jpg";
import beach from "../images/life/beach.jpg";
import dog from "../images/life/dog.jpg";
import home from "../images/life/home.jpg";
import italy from "../images/life/italy.jpg";
import paris from "../images/life/paris.mov";
import berlin from "../images/life/berlin.mov";
import vienna from "../images/life/vienna.jpg";
import lola from "../images/life/lola.jpg";
import imperia from "../images/life/imperia.png";
import cabin from "../images/life/cabin.png";

const localImages = [
  { src: olderNiece, text: "New York" },
  { src: fishes, text: "Croatia" },
  { src: paris, text: "Paris" },
  { src: buda, text: "Budapest" },
  { src: italy, text: "Italy" },
  { src: cabin, text: "Blairstown" },
  { src: youngerNiece, text: "Jersey" },
  { src: beach, text: "Split" },
  { src: home, text: "Williamsburg" },
  { src: vienna, text: "Vienna" },
  { src: dog, text: "Blairstown" },
  { src: board, text: "Stowe" },
  { src: berlin, text: "Berlin" },
  { src: lola, text: "France" },
  { src: imperia, text: "Imperia" },
];

export default function Life() {
  const isPhone = useMediaQuery("(max-width:600px)");
  const isVideo = (filename) => {
    return /\.(mov|mp4)$/i.test(filename);
  };

  const videoRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        textAlign="center"
        sx={{
          width: "80%",
          fontSize: isPhone ? "4vw" : "1.5vw",
          wordWrap: "break-word",
          color: "#E0E1DD",
        }}
      >
        some people and places that live in my mind rent free
      </Box>
      <Box
        sx={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageList variant="masonry" cols={isPhone ? 1 : 2} gap={15}>
          {localImages.map((imageObj, index) => (
            <ImageListItem key={imageObj.src}>
              {isVideo(imageObj.src) ? (
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  src={imageObj.src}
                  alt={`Video ${index}`}
                  muted
                  autoPlay
                  loop
                  loading="lazy"
                  style={{ width: "100%", height: "auto" }}
                  playsInline
                />
              ) : (
                <img
                  src={imageObj.src}
                  alt={`Image ${index}`}
                  loading="lazy"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  background: "#222831",
                  padding: 0.5,
                  color: "#f6f7f9",
                  fontSize: 12,
                  borderRadius: 1,
                }}
              >
                {imageObj.text}
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}
